@layer utilities {
  .fs10 {
    font-size: 10px;
  }

  .fs11 {
    font-size: 11px;
  }

  .fs12 {
    font-size: 12px;
  }

  .fs13 {
    font-size: 13px;
  }

  .fs14 {
    font-size: 14px;
  }

  .fs15 {
    font-size: 15px;
  }

  .fs16 {
    font-size: 16px;
  }

  .fs18 {
    font-size: 18px;
  }

  .fs20 {
    font-size: 20px;
  }

  .fs21 {
    font-size: 21px;
  }

  .fs24 {
    font-size: 24px;
  }

  .fs36 {
    font-size: 36px;
  }

  .fs42 {
    font-size: 42px;
  }

  .border-3 {
    border-width: 3px;
  }

  .no-border-last > :last-child {
    border: none !important;
    border-color: transparent !important;

    a {
      border: none !important;
      border-color: transparent !important;
    }
  }

  .no-mb-last > :last-child {
    margin-bottom: 0 !important;
  }

  .no-pb-last > :last-child {
    padding-bottom: 0 !important;
  }

  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .modal-sidebar-scroll-fixed-h {
    height: 50vh;
  }
  .modal-sidebar-scroll-max-h {
    max-height: calc(90vh - 46vh);
  }

  .modal-scroll-max-h {
    max-height: calc(90vh - 128px);
  }

  .documentType__list {
    ul {
      list-style: disc;
      padding-left: 24px;
      margin-top: 6px;

      li {
        margin-bottom: 6px;
      }
    }

    ol {
      list-style: decimal !important;
      padding-left: 24px !important;
      margin-top: 6px !important;

      li {
        margin-bottom: 6px !important;
      }
    }

    p,
    h4 {
      margin-top: 6px !important;
      margin-bottom: 6px !important;
    }

    h3 {
      font-size: 16px;
      font-family: 'Open sans';
      font-weight: 500 !important;
      margin-top: 6px !important;
      margin-bottom: 10px !important;
    }

    h4 {
      font-size: 14px;
      font-family: 'Open sans';
      font-weight: 700 !important;
    }

    blockquote {
      @apply bg-blue-50 border rounded-md border-blue-500 p-3 mt-4;

      * {
        margin: 0 !important;
        padding: 0 !important;
        font-weight: 600;
      }
    }
  }

  .visto-layout-height {
    height: calc(100% - 42px);
  }

  .visto-component {
    @apply bg-white border border-visto-gray p-6 rounded-lg;
  }
}

.no-appearance {
  select {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    text-indent: 0.01px !important;
    text-overflow: '' !important;
    background-image: none !important;
  }

  select::-ms-expand {
    display: none !important;
  }
}
