.progressTracker {
  list-style: none;
  position: relative;
  z-index: 1;

  li {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    position: relative;

    .progressTracker__circleWrapper {
      border: 3px solid #ffffff;
    }

    .progressTracker__circle {
      display: block;
      border-radius: 50%;
      border: 2px solid $darkBlue;
      margin-right: 8px;
      background: #ffffff;
      width: 8px;
      height: 8px;

      &:before {
        content: '';
        position: absolute;
        border-left: 2px dashed $darkBlue;
        left: 6.1px;
        top: -34px;
        bottom: 0;
        z-index: -1;
        height: 100%;
        min-height: 100%;
      }
    }

    p {
      margin-left: 12px;
      font-weight: 400;
      font-size: 14px;
      opacity: 0.5;
    }

    &.progressTracker__itemCompleted {
      .progressTracker__circle {
        background: $darkBlue;
        &:before {
          border-left: 2px solid $darkBlue;
        }
      }
    }

    &.progressTracker__itemCurrent {
      .progressTracker__circle {
        width: 13px;
        height: 13px;
        margin-left: -2.6px;
        margin-right: 5px;
        background: $darkBlue;
        &:before {
          border-left: 2px solid $darkBlue;
        }
      }

      .progressTracker__label {
        font-weight: 700;
        text-decoration: underline;
      }
    }

    &.progressTracker__itemCurrent,
    &.progressTracker__itemCompleted {
      p {
        opacity: 1;
      }
    }

    &:first-child {
      .progressTracker__circle {
        &:before {
          content: none;
        }
      }
    }

    &:last-child {
      .progressTracker__circle {
        &:before {
          height: calc(100% + 20px);
        }
      }

      padding-bottom: 0;
    }
  }
}

.react-pdf__Document {
  height: 500px; // TODO: change depending of the last
  overflow: auto;

  .react-pdf__Page {
    .react-pdf__Page__canvas {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.visto-react-date-picker {
  border: none !important;

  .react-datepicker__header {
    background-color: #fff !important;
  }

  .react-datepicker__day-name {
    font-weight: bold !important;
  }

  .react-datepicker__day--selected {
    background-color: #0d3d4d !important;
  }

  .react-datepicker__navigation {
    top: 8px !important;
  }

  @apply shadow-2xl;
}

.notes-content {
  & ol {
    @apply list-decimal ml-6;
  }

  & ul {
    @apply list-disc ml-6;
  }
}

// DraftJS
.block-toolbar-container {
  .t16lpgj {
    @apply bg-gray-100 border-b border-gray-700;
    box-shadow: none;
    border: 0;

    button {
      @apply bg-gray-100;

      &:hover {
        @apply bg-gray-200;
      }
    }
  }
}
// toolbar.div
.t16lpgj {
  border: 0 !important;
}
// toolbar.div.button
.bc4rxid {
  padding-top: 0px !important;
  font-size: 1.5em !important;
  display: inline-flex !important;
  align-items: center;
  svg {
    margin: auto;
  }
}

//toolbar.div.button emoji
.e8k2yoa,
.e13wqaj6 {
  border: 0 !important;
  border-radius: 0 !important;
  width: 36px !important;
  height: 34px !important;
  vertical-align: bottom !important;
}
// emoji popup
.ejr02pv {
  bottom: 130px;
}
// emoji popup > images
.e11mkpma {
  margin: auto;
}

.DraftEditor-editorContainer {
  * {
    font-size: 14px;
  }

  img {
    max-width: 150px;
    max-height: 50px;
  }
}

.public-DraftEditorPlaceholder-root {
  font-size: 14px;
}

.wysiwyg-mention {
  background-color: #dae9f7;
  border-radius: 20px;
  padding: 4px 8px;
  font-weight: 600;
}

// MUI Data Grid
.MuiDataGrid-cell {
  overflow: auto !important;
}

.MuiDataGrid-columnSeparator {
  display: none !important;
}

.MuiDataGrid-columnHeaderTitleContainer {
  padding: 0 !important;
}

.MuiDataGrid-panel {
  .MuiPaper-root {
    @apply p-6;
    @apply rounded-lg;
    @apply border;
    @apply border-visto-gray;
    @apply bg-gray-50;
    @apply border-gray-200;

    box-shadow: none !important;
  }

  .MuiInput-root {
    margin-right: 18px;
  }

  .MuiNativeSelect-select,
  .MuiInput-input {
    font-size: 14px;
  }

  .MuiDataGrid-panelFooter {
    justify-content: flex-end;
  }

  .MuiDataGrid-filterForm {
    @apply bg-gray-100;
    @apply rounded-lg;
    @apply p-3;
    @apply mb-2;
  }

  .MuiDataGrid-panelContent {
    overflow: visible !important;
  }
}

// Shepherd Guided On-boarding
.shepherd-element {
  border-radius: 0.5rem !important;
  // border: 1px solid black !important;

  @apply px-6 py-8 shadow-lg;
}

.shepherd-arrow::before {
  background-color: $white !important;
}

.shepherd-header {
  text-align: center !important;
  background: none !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;

  .shepherd-title {
    justify-content: center !important;
    font-weight: 800;

    @apply fs21;
  }
}

.shepherd-footer {
  justify-content: center !important;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0 !important;

  @apply mt-4;
}

.shepherd-next-button,
.shepherd-exit-button {
  border-radius: 40px !important;
  font-weight: 700;
  padding: 8px 40px 8px 40px !important;
  font-size: 16px !important;
}

.shepherd-next-button {
  background: $darkBlue !important;
  color: $white !important;

  &:hover {
    opacity: 0.8;
  }
}

.shepherd-basic-button {
  font-size: 14px;
  background: none !important;
  color: #969696 !important;
  padding: 8px 4px 0 0 !important;
  text-decoration: underline;
}

.shepherd-exit-button {
  background: $white !important;
  color: $darkBlue !important;
  border: 1px solid $darkBlue !important;

  &:hover {
    @apply bg-gray-200;
  }
}

.shepherd-cancel-icon {
  position: absolute !important;
  top: 2px;
  right: 10px;
}

.iframe-video-container {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.iframe-video-container::after {
  padding-top: 56.25%;
  display: block;
  content: '';
}

.iframe-video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.draftJs__toolbar {
  > div {
    @apply bg-gray-50 shadow-none border-0 rounded-tl-lg rounded-tr-lg;

    > div {
      @apply px-0.5;

      button {
        @apply bg-gray-50 flex items-center justify-center;
      }
    }
  }
}

.animate__greenCheck {
  svg {
    display: block;
    height: 80px;
    width: 80px;
    @apply text-green-600;
  }

  .circle {
    stroke-dasharray: 76;
    stroke-dashoffset: 76;
    animation: draw 0.5s forwards;
    animation-delay: 0.5s;
  }

  .tick {
    stroke-dasharray: 18;
    stroke-dashoffset: 18;
    animation: draw 0.5s forwards 0.5s;
    animation-delay: 0.5s;
  }

  @keyframes draw {
    to {
      stroke-dashoffset: 0;
    }
  }
}

.progressBar {
  @apply bg-gray-200;
  border-radius: 20px;
  position: relative;
  height: 28px;

  .progressBar__done {
    border-radius: 20px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 0;
    opacity: 0;
    transition: 1s ease 0.3s;
  }
}

.message-content {
  p {
    @apply font-normal;
    height: auto;
    font-size: 14px;
  }

  li {
    font-size: 14px;
  }

  ol {
    @apply list-decimal ml-6;
  }

  ul {
    @apply list-disc ml-6;
  }
}

.custom-h-messages {
  height: calc(100% - 7rem);
}

// Google Translate Widget
#goog-gt-tt {
  display: none !important;
}

// Hides the spinning logo in the top left
.VIpgJd-ZVi9od-aZ2wEe-wOHMyf {
  display: none !important;
}

.skiptranslate {
  .skiptranslate {
    display: none;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }
}

// TinyMCE
.tox-statusbar__branding {
  display: none !important;
}

.mceNonEditable {
  background-color: #ccefff;
  border-radius: 4px;
  font-weight: 600;
  padding: 4px 6px;
  font-size: 14px;
  border: 1px solid #b3d4fc;
  cursor: grab !important;
  outline: 1 !important;
  border-style: dashed;
  color: #000000 !important;
}

.mceNonEditable.signature {
  font-size: 16px;
  display: block;
  text-align: center;
  background-color: #daffcc;
  border-color: #8af363;
  padding: 12px 6px;
}

.mceNonEditable.document {
  display: block;
  text-align: center;
  background-color: #f2f2f2;
  border-color: #b6b2b2;
  font-size: 16px;
  padding: 12px 6px;
}

.mceNonEditable .description {
  display: block;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
}

.mceNonEditable.condition {
  background-color: #ffeeb8;
  border-color: #efcb00;
}

.mceNonEditable.condition .uniqueId {
  font-size: 10px;
  font-weight: 400;
  font-style: italic;
}

.mceNonEditable.condition .conditionHighlight {
  text-decoration: underline;
  font-style: italic;
}

span.condition.mceNonEditable::after {
  content: 'ID: ' attr(data-unique-id);
  color: #555;
  font-size: 10px;
  padding-left: 6px;
  padding-top: 5px;
}

// React Modal
.ReactModal__Content {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  right: auto !important;
  bottom: auto !important;
  margin-right: -50% !important;
  transform: translate(-50%, -50%) !important;
  padding: 40px !important;
  min-width: auto !important;
  border-radius: 5px !important;
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.6) !important;
  z-index: 99 !important;
}

.RepeaterTableEditModal {
  .ReactModal__Content {
    max-height: 75% !important;
  }

  @media (min-width: 768px) {
    .ReactModal__Content {
      min-width: 600px !important;
    }
  }
}

.RepeaterTableFullScreenModal {
  .ReactModal__Content {
    max-height: 95% !important;
    max-width: 95% !important;
  }
}

// Circle Progress Bar
.circle-progress-bar {
  .circular-chart {
    display: block;
  }

  .circle-bg {
    fill: none;
    stroke: #eee;
    stroke-width: 3.8;
  }

  .circle {
    fill: none;
    stroke-width: 2.8;
    stroke-linecap: round;
    animation: progress 1s ease-out forwards;
  }

  @keyframes progress {
    0% {
      stroke-dasharray: 0 100;
    }
  }

  .circular-chart.blue .circle {
    stroke: #3c9ee5;
  }

  .percentage {
    fill: #666;
    font-family: sans-serif;
    font-size: 0.5em;
    text-anchor: middle;
  }

  .label {
    fill: #666;
    font-family: sans-serif;
    font-size: 0.2em;
    text-anchor: middle;
  }
}
