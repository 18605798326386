@tailwind base;
@tailwind components;
@tailwind utilities;

$darkBlue: #0d3d4d;
$white: #ffffff;
$fontHeavy: 800;

@import './../partials/utilities';
@import './../partials/tooltip';
@import './../partials/chat';
@import './../partials/syncfusion-pdf';

html {
  box-sizing: border-box;

  font-size: 16px;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 800;
}

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  color: inherit;
  text-decoration: none;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $darkBlue;
  color: $white;
  font-size: 15px;
  font-weight: bold;
  padding: 8px 22px;
  border-radius: 22px;
}

.hidden-toolbar-container:focus-within > .hidden {
  display: block;
}

.highlight-links {
  a {
    text-decoration: underline;
    font-weight: 600;
  }
}

/* CUSTOM css */
.icon-menu {
  height: 20px;
  width: 18px;
}

.content-area {
  height: calc(100vh - 64px);
}

.content {
  > *:first-child {
    margin-top: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 6px;
    margin-top: 24px;
  }

  h1,
  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 20px;
  }

  h4 {
    font-size: 18px;
  }

  h5 {
    font-size: 16px;
  }

  p,
  ul,
  ol {
    margin-bottom: 22px;
  }

  .disableSpacing {
    p,
    ul,
    ol {
      margin-bottom: 0 !important;
    }
  }

  ul {
    padding-left: 24px;
    list-style-type: disc;

    li {
      margin-bottom: 4px;

      > p {
        margin-bottom: 0;
      }
    }
  }

  ol {
    padding-left: 24px;
    list-style-type: decimal;

    li {
      margin-bottom: 4px;
    }
  }

  p + ul,
  p + ol {
    margin-top: -16px;
  }

  li {
    > ul,
    > ol {
      margin-top: 4px;
      margin-bottom: 4px;
    }
  }

  a {
    @apply text-blue-700;
    text-decoration: underline;
  }

  video {
    width: 100% !important;
    height: auto !important;
    margin-bottom: 22px;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }

  blockquote {
    @apply bg-blue-50 border rounded-md border-blue-500 p-3 mt-4;

    * {
      margin: 0 !important;
      padding: 0 !important;
      font-weight: 600;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
  }

  th {
    background-color: #4caf50;
    color: white;
    font-weight: bold;
    padding: 15px;
    text-align: left;
  }

  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  caption {
    padding: 10px;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    color: #333;
  }
}

.content.small {
  p,
  li {
    font-size: 14px;
  }
}

.content.xs {
  p,
  li {
    font-size: 12px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 12px;
    margin-bottom: 6px;
    margin-top: 6px;
  }
}

.content.article {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 16px;
    font-weight: 700;
  }

  h2 + h3 {
    margin-bottom: 22px;
    margin-top: -16px;
  }

  ol,
  ul {
    li {
      margin-bottom: 8px;
    }
  }
}

.content-links {
  a {
    @apply text-blue-700;
    text-decoration: underline;
    font-weight: 600;
  }
}

.sidebar {
  height: calc(100vh - 64px);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input:focus,
textarea:focus,
select:focus {
  outline: none !important;
  border-color: inherit;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.dynamic-api-form-fieldset:disabled {
  opacity: 0.6;

  * {
    cursor: not-allowed;
  }
}

ul.comma-list {
  li:after {
    content: ', ';
  }

  li:last-child::after {
    content: '';
  }
}

input::placeholder {
  @apply text-slate-300;
}

@import './../partials/components';
