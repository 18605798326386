@tailwind base;
@tailwind components;
@tailwind utilities;

.markdownStyle {
  a {
    @apply text-blue-700;
    text-decoration: underline;
  }

  a:hover {
    @apply text-blue-500;
  }

  a:active {
    @apply text-blue-900;
  }

  .language-json {
    white-space: break-spaces;
  }
}
